import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form,
    FloatingLabel
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ModalErro from './ModalErro';
import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';

ModalEmail.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    funcPos: PropTypes.func,
    Email: PropTypes.string,
    setEmail: PropTypes.func.isRequired,
    Wpp: PropTypes.string,
    setWpp: PropTypes.func.isRequired,
    cupom: PropTypes.any
}

export default function ModalEmail({ showModal, setShowModal, funcPos, Email, setEmail, cupom, Wpp, setWpp }) {

    //console.log(funcPos);

    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgErro, setMsgErro] = React.useState("");

    return (

        <Modal
        show={showModal}
        backdrop={!cupom ?"static" : ''}
        onHide={!cupom ? () => { setShowModal(false) } : null }
        keyboard={false}
        centered
        size='lg'
        >
            <Modal.Header closeButton={!cupom}>
                
                <Modal.Title>
                    {cupom != null ?
                    <div>
                        <h4>Venda gerada com sucesso!</h4>
                        <h4>Cupom N.º {cupom}</h4>
                    </div>
                    :null}
                    
                    <h4>Email e/ou Wpp do cliente (opcional):</h4> 
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <ModalErro
                    showModal={showModalErro}
                    setShowModal={setShowModalErro}
                    msg={msgErro}
                    permiteFechar={false}
                    
                />

                <Container>
                    <Row>
                        <Col>
                        
                            <FloatingLabel
                                controlId="floatingInputEmail"
                                label="Email do cliente"
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="email"
                                    value={Email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                            </FloatingLabel>
                        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        
                        <FloatingLabel
                            controlId="floatingInputWpp"
                            label="Whatsapp do cliente"
                            className="mb-3"
                        >
                            <InputMask
                                mask="(99) 99999-9999"
                                value={Wpp}
                                onChange={(e) => setWpp(e.target.value)}
                            >
                                {() => <Form.Control type="text" />}
                            </InputMask>
                        </FloatingLabel>
                        
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <div className="d-grid gap-2">
                                <Button
                                variant="danger"
                                onClick={() => {

                                    setEmail("");
                                    setWpp("");
                                    funcPos("");

                                    
                                }}
                                >SEM EMAIL e/ou WPP</Button>{' '}

                            </div>

                        </Col>
                        <Col>

                            <div className="d-grid gap-2">
                                <Button
                                variant="success"
                                onClick={() => {

                                    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                    let numWppFormatado = Wpp.replace(/\D/g, '');

                                    if(regex.test(Email) || numWppFormatado.length >= 11){

                                        if(Wpp.length >= 15){
                                            setWpp("55" + numWppFormatado );
                                        }

                                        console.log("55" + numWppFormatado);

                                        funcPos();
                                        
                                    }else{
                                        setMsgErro("Email inválido");
                                        setShowModalErro(true);
                                    }

                                    
                                }}
                                >COM EMAIL e/ou WPP</Button>{' '}

                            </div>

                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

    );

}
