import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal
} from 'react-bootstrap';
import PropTypes from 'prop-types';

ModalDeslogar.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired

}

export default function ModalDeslogar({showModal, setShowModal}) {

    return(

        <Modal
        show={showModal}
        onHide={() => { setShowModal(false); }}
        keyboard={false}
        id="modalDeslogar"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title><h1>Sair</h1> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row style={{marginBottom: "5%"}}>
                        <Col style={{textAlign: "center", alignItems: "center"}}><h3>Deseja realmente sair?</h3></Col>
                    </Row>
                    <Row >

                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    setShowModal(false);
                                    sessionStorage.removeItem("informacoesUsuario");
                                    window.location.href = "/login";

                                }}
                                >Sim</Button>{' '}
                            </div>
                        </Col>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                variant="danger"
                                onClick={() => setShowModal(false)}
                                >Não</Button>{' '}
                            </div>
                        </Col>

                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

    );
    

}
