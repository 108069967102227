import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form,
    FloatingLabel
} from 'react-bootstrap';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalErro from './ModalErro';

ModalAdminSenha.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    funcPos: PropTypes.func.isRequired

}

export default function ModalAdminSenha({ showModal, setShowModal, user, funcPos }){

    const [senha, setSenha] = React.useState("");

    //Modal erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    
    return(

        <Container>

            {/* Modal loading */}
            {/* <ModalLoad showModal={showModalLoad} /> */}

            {/* Modal erro */}
            <ModalErro showModal={showModalErro} setShowModal={ (e) => { setShowModalErro(e) } } msg={errorMsg} permiteFechar={false} /> 

            {/* Modal sucesso*/}
            {/* <ModalSucesso showModal={showModalSucess} 
            setShowModal={ (e) => { setShowModalSucess(e) } } msg={ModalSucessMsg} /> */}

            <Modal
            show={showModal}
            onHide={ () => { setShowModal(false) } }
            keyboard={false}
            id="modalAdminSenha"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h1>Senha Admin:</h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                            <Col>
                            
                                <FloatingLabel
                                    controlId="floatingInputSenha"
                                    label="Senha: "
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="password"
                                        value={senha}
                                        onChange={(e) => {
                                            setSenha(e.target.value)
                                        }}
                                    />
                                </FloatingLabel>
                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <div className="d-grid gap-2">
                                    <Button
                                    variant="success"
                                    onClick={() => {

                                        if(senha == null || senha == undefined || senha == ""){
                                            
                                            return;

                                        }

                                        // console.log("Senha: ", senha);
                                        // console.log("Senha Exclusão: ", user.operador.senhaExclusaoVenda);

                                        if(senha == user.operador.senhaExclusaoVenda){
                                            
                                            funcPos();
                                            setSenha("");
                                            setShowModal(false);
                                            return;

                                        }else{

                                            setErrorMsg("Senha Incorreta");
                                            setShowModalErro(true);

                                        }

                                    }}
                                    >OK</Button>{' '}

                                </div>

                            </Col>
                        </Row>
                        <Row style={{marginTop: "1%"}}>
                            <Col>

                                <div className="d-grid gap-2">
                                    <Button
                                    variant="danger"
                                    onClick={() => {

                                        setShowModal(false);
                                        
                                    }}
                                    >Cancelar</Button>{' '}

                                </div>

                            </Col>
                        </Row>
                    </Container>
                    
                </Modal.Body>
            </Modal>

        </Container>

    );

}