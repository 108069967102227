import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form,
    FloatingLabel
} from 'react-bootstrap';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import global from '../global';
import ModalLoad from './ModalLoad';
import ModalErro from './ModalErro';
import ModalSucesso from './ModalSucesso';

ModalAlterarSenhaOperador.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired

}

export default function ModalAlterarSenhaOperador({ showModal, setShowModal, user }) {

    const [senha, setSenha] = React.useState("");
    const [senhaNovamente, setSenhaNovamente] = React.useState("");

    //Modal loading
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //Modal erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");

    //Modal sucesso
    const [showModalSucess, setShowModalSucess] = React.useState(false);
    const [ModalSucessMsg, setModalSucessMsg] = React.useState("");


    return(

        <Container>

            {/* Modal loading */}
            <ModalLoad showModal={showModalLoad} />

            {/* Modal erro */}
            <ModalErro showModal={showModalErro} setShowModal={ (e) => { setShowModalErro(e) } } msg={errorMsg} permiteFechar={false} />

            {/* Modal sucesso*/}
            <ModalSucesso showModal={showModalSucess} 
            setShowModal={ (e) => { setShowModalSucess(e) } } msg={ModalSucessMsg} />

            <Modal
            show={showModal}
            onHide={ () => { setShowModal(false) } }
            keyboard={false}
            id="modalCupomFicalEmail"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h1>Alterar senha operador:</h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                            <Col>
                            
                                <FloatingLabel
                                    controlId="floatingInputSenha"
                                    label="Nova senha: "
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="password"
                                        value={senha}
                                        onChange={(e) => {
                                            setSenha(e.target.value)
                                        }}
                                    />
                                </FloatingLabel>
                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            
                                <FloatingLabel
                                    controlId="floatingInputSenhaNovamente"
                                    label="Nova senha novamente: "
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="password"
                                        value={senhaNovamente}
                                        onChange={(e) => {
                                            setSenhaNovamente(e.target.value)
                                        }}
                                    />
                                </FloatingLabel>
                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <div className="d-grid gap-2">
                                    <Button
                                    variant="success"
                                    onClick={() => {

                                        setShowModalLoad(true);

                                        if(senha == null || senha == undefined || senha == "" || senhaNovamente == null || senhaNovamente == undefined || senhaNovamente == ""){
                                            
                                            setShowModalLoad(false);
                                            setErrorMsg("Preencha todos os campos");
                                            setShowModalErro(true);
                                            return;

                                        }

                                        if(senha != senhaNovamente){
                                            
                                            setShowModalLoad(false);
                                            setErrorMsg("As senhas devem ser iguais");
                                            setShowModalErro(true);
                                            return;

                                        }

                                        axios.post(global.URL_BASE + global.CAMINHO_ALTERARSENHA, {

                                            idOrg: user.operador.idOrganizacao,
                                            idFilial: user.operador.idFilial,
                                            idOperador: user.operador.idOperador,
                                            novaSenha: senha,
                                            userWS: global.USER_WS,
                                            passWS: global.PASS_WS

                                        })
                                        .then(function (response) {

                                            let result = response.data; 
                                            
                                            if(result.code == 0){

                                                setShowModalLoad(false);
                                                setModalSucessMsg("Senha alterada com sucesso");
                                                setShowModalSucess(true);
                                                setShowModal(false);

                                            }else{

                                                setShowModalLoad(false);
                                                setErrorMsg("Ocorreu um erro ao tentar alterar a senha do operador: " + result.msg);
                                                setShowModalErro(true);

                                            }

                                        })
                                        .catch(function (error) {
                                            
                                            setShowModalLoad(false);
                                            setErrorMsg("Ocorreu um erro ao tentar alterar a senha do operador: " + error.message);
                                            setShowModalErro(true);

                                        });

                                    }}
                                    >Alterar Senha</Button>{' '}

                                </div>

                            </Col>
                        </Row>
                        <Row style={{marginTop: "1%"}}>
                            <Col>

                                <div className="d-grid gap-2">
                                    <Button
                                    variant="danger"
                                    onClick={() => {

                                        setShowModal(false);
                                        
                                    }}
                                    >Cancelar</Button>{' '}

                                </div>

                            </Col>
                        </Row>
                    </Container>
                    
                </Modal.Body>
            </Modal>

        </Container>

        

    );

}
